<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.field_id"
              :label="t('fields.field')"
              :placeholder="t('fields.field')"
              :items="fieldsOptions"
              item-text="name"
              item-value="id"
              outlined
              :clearable="option!==2"
              dense
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
              @change="onSelectField"
            />
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.start_time"
              :label="t('videos.time')"
              :placeholder="t('videos.time')"
              :items="chunksOptions"
              item-text="start_time"
              item-value="start_time"
              outlined
              :clearable="option!==2"
              dense
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
              :disabled="!dataComputed.field_id"
            />
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.video_time"
              :placeholder="t('videos.minutes')"
              :items="minuteOptions"
              item-text="title"
              item-value="value"
              outlined
              :clearable="option!==2"
              dense
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            />
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.weekdays"
              :label="t('videos.weekdays')"
              :items="weekdays"
              item-text="text"
              item-value="value"
              :menu-props="{ maxHeight: '400' }"
              multiple
              persistent-hint
              dense
              outlined
              :clearable="option!==2"
              class="mb-0"
              :append-icon="option === 2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  label
                  dense
                  :input-value="data.selected"
                  @click="data.select"
                >
                  {{ data.item.text }}
                </v-chip>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-text-field
              v-model="computedPhoneNumbers"
              :label="t('notify_players.phone_numbers')"
              dense
              outlined
              readonly
              :rules="computedRequirePhone ? [required] : []"
              :append-icon="option !== 2 ? icons.mdiPencilOutline : icons.mdiEyeOutline"
              @click:append="isModalOpen = true"
              @click="isModalOpen = true"
            />
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.status"
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              dense
              outlined
              :clearable="option!==2"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>

    <v-dialog
      v-model="isModalOpen"
      max-width="420px"
    >
      <v-card
        class="pa-3"
        rounded="10"
        style="width: 100%"
      >
        <v-form ref="formDialog">
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            dense
            class="px-4"
          >
            <v-toolbar-title class="text-h6 white--text pl-0">
              {{ t('notify_players.notify_players') }}
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
            <v-row class="justify-center align-center">
              <v-col
                v-for="(phoneInput, index) in phoneInputs"
                :key="index"
                cols="12"
                md="10"
                class="py-0"
              >
                <div class="d-flex justify-space-between align-items-start">
                  <v-select
                    v-model="phoneInput.code"
                    dense
                    outlined
                    :items="phoneCodeOptions"
                    item-text="text"
                    item-value="value"
                    style="
                      width: 35%;
                    "
                    :append-icon="option!==2 ? icons.mdiMenuDown : null"
                    :readonly="option===2"
                    hide-details
                    :rules="[required]"
                    @change="() => phoneInput.value = null"
                  >
                    <template v-slot:item="{ item }">
                      <img
                        :src="item.image"
                        :alt="item.value"
                        width="20"
                        height="auto"
                        class="mr-2"
                      >
                      {{ item.text }}
                    </template>
                  </v-select>

                  <v-text-field
                    v-model="phoneInput.value"
                    :label="t('notify_players.phone')"
                    dense
                    outlined
                    class="pl-2"
                    :class="{ 'mb-2': index > 0 }"
                    :disabled="!phoneInput.code"
                    :readonly="option===2"
                    :hide-details="index > 0"
                    :rules="(index === 0 && computedRequirePhone) ? [required, validatePhones(phoneInput.value, phoneInput)] : [validatePhones(phoneInput.value, phoneInput)]"
                    :append-icon="option !== 2 && index > 0 ? icons.mdiClose : null"
                    @click:append="deletePhoneNumber(index)"
                    @keydown.enter.prevent="addPhoneNumber"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col
                v-if="option !== 2"
                cols="12"
                md="10"
                class="py-0"
              >
                <p
                  id="addBtnForm"
                  class="text-body-1 ma-0 btn-add"
                >
                  + {{ t('notify_players.add_phone') }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex flex-column justify-center py-3">
            <p class="text-xs text-center mb-3">
              {{ t('notify_players.message') }}
            </p>
            <div class="d-flex justify-space-between">
              <v-btn
                class="text-xs"
                color="lightGray"
                dark
                width="30%"
                rounded
                outlined
                @click="isModalOpen = false"
              >
                {{ t('tooltip.close') }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import { mdiMenuDown, mdiClose, mdiPencilOutline, mdiEyeOutline } from '@mdi/js'
import { ref, computed, onMounted, watch, onBeforeMount } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { isDev } from '@core/utils'
import usePhone from '@core/utils/usePhone'
import useCountry from '@core/utils/useCountry'
import useSelectOptions from '@core/utils/useSelectOptions'

// eslint-disable-next-line object-curly-newline
import { getFieldsByFacility, getChucksByField } from '@api'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'

import useRecurringOrderList from './useRecurringOrderList'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    weekdays: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const {
      userData,
      groupsOptions,
      facilitiesOptions,
      fieldsOptions,
      chunksOptions,
    } = useRecurringOrderList()
    const { getCountryCode } = useCountry()
    const { configFacility } = useSelectOptions()
    const { phoneCodeOptions, initPhoneConfig, validatePhones } = usePhone()
    const form = ref(null)
    const formDialog = ref(null)
    const isModalOpen = ref(false)
    const initCode = ref('')
    const phoneInputs = ref([
      { value: null, code: null, phone: null },
    ])

    const facilitySelected = ref(null)
    const computedRequirePhone = computed(() => {
      if (facilitySelected.value) return facilitySelected.value.order_phone_number === 'Y'

      return false
    })

    const computedLocale = computed(() => t('locale'))
    const computedOption = computed(() => props.option)
    const dataComputed = computed(() => props.dataParams)
    const computedPhoneNumbers = computed(() => phoneInputs.value.filter(e => e.phone).map(e => e.phone).join(', '))

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const minuteOptions = ref([
      { title: '60 Min', value: 60 },
      { title: '90 Min', value: 90 },
      { title: '120 Min', value: 120 },
      { title: '150 Min', value: 150 },
    ])

    const onSaveAction = () => {
      if (form.value.validate()) {
        dataComputed.value.phone_numbers = phoneInputs.value.filter(e => e.phone).map(e => e.phone)

        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const fetchFields = async () => {
      if (configFacility.value) {
        const fields = await getFieldsByFacility(configFacility.value)
        fieldsOptions.value = fields.data
      } else fieldsOptions.value = []
    }

    const fetchChunks = async fieldId => {
      if (fieldId) {
        const chunks = await getChucksByField(fieldId)
        chunksOptions.value = chunks.data
      } else chunksOptions.value = []
    }

    const onSelectField = value => {
      chunksOptions.value = []
      fetchChunks(value)
    }

    const addPhoneNumber = () => {
      if (phoneInputs.value.length < 30) {
        phoneInputs.value.push({ value: null, code: initCode.value, phone: null })
      }
    }

    const deletePhoneNumber = index => {
      phoneInputs.value.splice(index, 1)
    }

    watch([isModalOpen], () => {
      if (isModalOpen.value) {
        setTimeout(() => {
          const btn = document.getElementById('addBtnForm')
          btn.addEventListener('click', addPhoneNumber)
        }, 200)
      }
    })

    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    onMounted(async () => {
      setTimeout(async () => {
        if (computedOption.value === 1) {
          const countryCode = getCountryCode()
          if (countryCode) {
            const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
            if (codeFinded) initCode.value = countryCode
            else initCode.value = 'US'
          }
          phoneInputs.value[0].code = initCode.value
        }

        if (computedOption.value !== 1) {
          let config = {}
          phoneInputs.value = props.dataParams.phone_numbers.map(e => {
            config = initPhoneConfig(e)

            return {
              value: config.phone_number,
              code: config.code,
              phone: e,
            }
          })

          await fetchChunks(props.dataParams.field_id)
        }
      }, 600)
      await fetchFields(configFacility.value)
      if (isDev.value) minuteOptions.value.unshift({ title: '5 Min', value: 5 })
    })

    return {
      form,
      formDialog,
      isModalOpen,
      phoneInputs,
      computedPhoneNumbers,
      phoneCodeOptions,
      statusOptions,
      computedLocale,
      dataComputed,
      computedRequirePhone,
      onCancelButton,
      changeTab,
      onSaveAction,
      onSelectField,
      validatePhones,
      initPhoneConfig,

      userData,
      groupsOptions,
      facilitiesOptions,
      fieldsOptions,
      chunksOptions,
      minuteOptions,
      facilitySelected,
      fetchFields,
      fetchChunks,
      addPhoneNumber,
      deletePhoneNumber,

      // validations
      required,

      // i18n
      t,

      icons: {
        mdiMenuDown,
        mdiClose,
        mdiPencilOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>
